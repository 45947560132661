import React, { useState, useEffect } from "react";
import moment from "moment";
import "moment/locale/id";
import { connect } from "react-redux";
import { setSelectedUserAttendancePermit, setLoading } from "../redux/action";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Drawer,
  Button,
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  Divider,
  TextField,
  MenuItem,
  Menu
} from '@material-ui/core'
import Loading from './Loading'
import css from '../pages/style.css'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const drawerWidth = 240;
const colorWeeks = '#ed2200';
const colorKantor = '#a9685e';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  textLabel:{
    paddingLeft: 20,
    paddingRight: 20
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  leftFilterContainer: {
    width: drawerWidth,
    height: 175,
    position: "fixed",
    top: 60,
    backgroundColor: '#fff',
    borderRight: '1px solid rgba(0, 0, 0, 0.12)'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    top: 220,
    paddingBottom: 200
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  loaderContainer: {
    position: 'fixed',
    top: '65px',
    left: '240px',
    width: 'calc(100vw - 240px)',
    height: 'calc(100vh - 65px)'
  },
  loader: {
    position: 'absolute',
    top: 'calc(50% - 50px)',
    left: 'calc(50% - 50px)'
  },
  expContainer: {
    zIndex:8000,
    backgroundColor: '#fff',
    position: 'absolute',
    top:0,
    right:0,
    bottom:0,
    left:0
  },
  listContainer: {
    cursor: 'pointer',
    textAlign: 'left',
    padding: 15
  },
  highLight: { backgroundColor: '#f04633', color: '#fff' }
}));

function PermanentDrawer(props) {
  const classes = useStyles();
  const [selectedUser, setSelectedUser] = useState({});
  const [searchKeyword, setSearchKeyword] = useState("")
  const [expired, setExpired] = useState(false)
  const [anchorEl, setAnchorEl] = useState({
    weeks: null,
    kantor: null
  });
  const [index, setIndex] = useState({
    weeks: 0,
    kantor: 0,
    area: 0
  });
  const [data, setData] = useState({
    token: null,
    thereIsData: false,
    principal: {
      name: "Principal..."
    },
    allEmployees: [],
    employees: [],
    kantor: [{
      value: 'all',
      label: 'Semua Area'
    }],
    dataKantor: [{
      value: 'all',
      label: 'Semua Area'
    }],
    weeks: [],
    areas: [{
      value: 'all',
      label: 'Semua Area'
    }],
    dataAreas: [{
      value: 'all',
      label: 'Semua Area'
    }],
    TTDate: {
      startDate: null,
      endDate: null
    }
  });

  const handleClick = (event, param) => {
    setAnchorEl({...anchorEl, [param]: event.currentTarget })
  }

  const handleClickMenu = (item, currentIndex, param) => {
    param === 'kantor' ? setIndex({ ...index, area: 0, [param]: currentIndex }) : setIndex({ ...index, [param]: currentIndex })
  }

  const handleClose = (event, param) => {
    setAnchorEl({ ...anchorEl, [param]: null })
  }

  const handleClickUser = (user) =>{
    setSelectedUser(user)
  }

  useEffect(() => {
    fetchUserAttendancePermit()
  }, [selectedUser, index.weeks]);

  useEffect(() => {
    let allEmployees = [...data.allEmployees]
    let allAreas = [...data.dataAreas]
    let allKantor = [...data.dataKantor]
    let filterKantor = data['dataKantor'][index['kantor']].value !== 'all'
    let filterArea = data['areas'][index['area']].value !== 'all'

    if (filterKantor) {
      allEmployees = allEmployees.filter(emp => emp.branch_id === data['dataKantor'][index['kantor']].value)
      allAreas = allAreas.filter(area => (area.branch_id === data['dataKantor'][index['kantor']].value || area.value === 'all'))
    }

    if (filterArea) {
      allEmployees = allEmployees.filter(emp => emp.second_city === data['areas'][index['area']].value)
    }

    if (!filterKantor && !filterArea) {
      allEmployees = [...data.allEmployees]
      allAreas = [...data.dataAreas]
      allKantor = [...data.dataKantor]
    }

    if (searchKeyword.length > 3) {
      allEmployees = allEmployees.filter(emp => emp.name.toLowerCase().includes(searchKeyword.toLowerCase()))
    }

    if(allEmployees.length > 0) {
      setSelectedUser(allEmployees[0])
    }
    setData({
      ...data,
      employees: allEmployees,
      areas: allAreas,
      kantor: allKantor
    })
  }, [index])

  const fetchUserAttendancePermit = async () => {
    props.setLoading(true)
    if (Object.keys(selectedUser).length > 0 && Object.keys(data['weeks'][index['weeks']].value).length > 0){
      let conditions = {
        ...selectedUser,
        dateRange: data['weeks'][index['weeks']]
      }
      let getAttendancePermit = await fetch(process.env[`REACT_APP_TIMETRACK_${process.env.REACT_APP_ENV}`] +`/api/v1/sessions/get-data-attendance`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(conditions)
      }).then(res => res.json())
      getAttendancePermit.rangeDate = data['weeks'][index['weeks']].value
      getAttendancePermit.TTDate = data.TTDate

      getAttendancePermit.imageSum = 0
      getAttendancePermit.attendances.map(att => {
        if(att.photo && att.server_photo) getAttendancePermit.imageSum += 1
      })
      props.setSelectedUserAttendancePermit(getAttendancePermit);
    }
    props.setLoading(false)
  }

  let topFilter = (currentData, param) => (
    <React.Fragment>
      <Button style={{
        border: `1px solid ${param === 'weeks' ? colorWeeks : colorKantor}`,
        color: `${param === 'weeks' ? colorWeeks : colorKantor}`,
        fontSize: 10,
        padding: "2px 0 2px 8px",
        borderRadius: 0,
        marginLeft: "2em"
        }}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={e => handleClick(e, param)}
      >
        {param === 'weeks' ? 'Weeks' : 'Kantor Perwakilan'} <ArrowDropDownIcon/>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl[param]}
        keepMounted
        open={Boolean(anchorEl[param])}
        onClose={e => handleClose(e, param)}
      >
        {
          currentData.map((item, currentIndex) => (
            <MenuItem
              style={currentIndex === (index[param]) ? { backgroundColor:'#e0e2e0' } : {} }
              onClick={e => handleClickMenu(item, currentIndex, param)}
              key={currentIndex}
            >
              {item.label}
            </MenuItem>
          ))
        }
      </Menu>
    </React.Fragment>
  )

  useEffect(() => {
    props.setLoading(true)
    let regexJWT = /[^/?JWT_][a-zA-Z0-9.\-_]+$/.exec(window.location.href)
    if(regexJWT){
      let jwtToken = regexJWT[0]
      fetchAttendance(jwtToken)
    } else {
      setExpired(true)
      props.setLoading(false)
    }
  }, []);

  let fetchAttendance = async (jwtToken) =>{
    let fetchData = await fetch(process.env[`REACT_APP_TIMETRACK_${process.env.REACT_APP_ENV}`] +`/api/v1/sessions/get-attendance`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ jwtToken })
    }).then(res => res.json())
    if(fetchData && fetchData.status !== 'error'){
      let weeks = await arrangeWeeks(fetchData.startDate, fetchData.endDate)
      let allKantor = await arrangeKantor(fetchData.employees)
      let allAreas = await arrangeArea(fetchData.employees)
      let allDataAreas = await fetchAndRearrangeArea(allAreas)
      setData({
        ...data,
        thereIsData: true,
        token: jwtToken,
        principal: fetchData.principal,
        allEmployees: fetchData.employees,
        employees: fetchData.employees,
        weeks,
        kantor: [...data.kantor, ...allKantor.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))],
        areas: [...data.areas, ...allDataAreas.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))],
        dataKantor: [...data.dataKantor, ...allKantor.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))],
        dataAreas: [...data.dataAreas, ...allDataAreas.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))],
        TTDate: {
          startDate: fetchData.startDate,
          endDate: fetchData.endDate
        }
      })
      setSelectedUser(fetchData.employees.length > 0 ? fetchData.employees[0] : {})
      props.setLoading(false)
    } else {
      setExpired(true)
      props.setLoading(false)
    }
  }

  let arrangeWeeks = async (startDate, endDate) => {
    let rangeDate = []
    let localStartDate = moment(startDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
    let localEndDate = moment(endDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
    let datePerWeek = []
    let weekStart = moment(startDate, 'YYYY-MM-DD').startOf('week').toString()
    let weekEnd = moment(endDate, 'YYYY-MM-DD').endOf('week').toString()

    for(let m=moment(weekStart); m <= moment(weekEnd); m.add(1, 'day')){
      rangeDate.push(m.format('YYYY-MM-DD'))
    }
    if(rangeDate.length > 0){
      let perWeek = {}
      let index = 0
      rangeDate.map((date) => {
        date = moment(date, 'YYYY-MM-DD')
        perWeek[index] = Array.isArray(perWeek[index]) ? [...perWeek[index]] : []
        if(date.isoWeekday() < date.endOf('week').isoWeekday()){
          perWeek[index] = [...perWeek[index], date._i]
        } else {
          perWeek[index] = [...perWeek[index], date._i]
          index+=1
        }
      })
      Object.keys(perWeek).map((k, index) => {
        let arrDate = perWeek[k]
        datePerWeek.push(
          {
            value: arrDate,
            label: getLabelDate(index === 0 ? localStartDate : arrDate[0], index + 1 === Object.keys(perWeek).length ? localEndDate : arrDate[arrDate.length - 1])
          }
        )
      })
    }

    return datePerWeek
  }

  let fetchAndRearrangeArea = async (data) => {
    let getCities = await fetch(process.env[`REACT_APP_TIMETRACK_${process.env.REACT_APP_ENV}`] + `/api/v1/principals/all-cities`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${process.env[`REACT_APP_TT_KEY`]}`
      },
    }).then(res => res.json())

    let resultData = data.map(item => {
      let index = getCities.findIndex(gc => gc._id === item.id)
      if(index !== -1){
        item.branch_id = getCities[index].branch_id ? getCities[index].branch_id : null
      }
      return item
    })
    return resultData
  }

  let getLabelDate = (startDate, endDate) => {
    let start_date = moment(startDate, 'YYYY-MM-DD');
    let end_date = moment(endDate, 'YYYY-MM-DD');

    let text = "";
    if (start_date.get("year") === end_date.get("year")) {
      if (start_date.get("month") === end_date.get("month")) {
        text = `${start_date.format("D")} - ${end_date.format("D MMMM YYYY")}`;
      } else {
        text = `${start_date.format("D MMMM")} - ${end_date.format(
          "D MMMM YYYY"
        )}`;
      }
    } else {
      text = `${start_date.format("D MMMM YYYY")} - ${end_date.format(
        "D MMMM YYYY"
      )}`;
    }

    return text;
  };

  let arrangeKantor = (employees) => {
    let kantor = []
    employees.map(emp => {
      if (emp && emp.branch_id){
        if (kantor.findIndex(k => k.value === emp.branch_id) === -1){
          (kantor.push({
            value: emp.branch_id,
            label: emp.area
          }))
        }
      }
    })
    return kantor.filter(k => Object.keys(k).length > 0)
  }

  let arrangeArea = (employees) => {
    let areas = []
    employees.map(emp => {
      if (emp && emp.second_city){
        if (areas.findIndex(k => k.value === emp.second_city) == -1){
          (areas.push({
            value: emp.second_city,
            label: emp.second_city,
            id: emp.city_id
          }))
        }
      }
    })
    return areas.filter(k => Object.keys(k).length > 0)
  }

  let searchKaryawan = async (e) => {
    let keywordEmp = []
    if(searchKeyword.length > 3){
      keywordEmp = data.employees.filter(emp => emp.name.toLowerCase().includes(e.toLowerCase()))
      setData({
        ...data,
        employees: keywordEmp,
      })
    } else if (searchKeyword.length === 1 && e === ""){
      setData({
        ...data,
        employees: data.allEmployees,
      })
    }
    setSearchKeyword(e)
  }

  const Row = ({ index, style }) => (
    <div
      className={`${classes.listContainer} ${data.employees[index].code === selectedUser.code ? classes.highLight : ''}`}
      onClick={e => handleClickUser(data.employees[index])}
      style={style}
    >
      <React.Fragment>
        <Typography variant="body1" noWrap style={{ fontWeight: "600" }}>
          {data.employees[index].name}
        </Typography>
        <Typography
          variant="body2"
          style={data.employees[index].code === selectedUser.code ? { color: '#fff' } : { color: '#000' }}
          component="p"
        >
          {data.employees[index].second_city ? `${data.employees[index].second_city} - ` : ''}{`${data.employees[index].area}`}
        </Typography>
        <Typography
          variant="body2"
          style={data.employees[index].code === selectedUser.code ? { color: '#fff' } : { color: 'rgba(0, 0, 0, 0.54)'}}
          component="p"
        >
          {data.employees[index].posisi}
        </Typography>
      </React.Fragment>
    </div>
  );

  return (
    <div className={classes.root}>
      <Loading/>
      <div className={classes.loaderContainer} style={{
        display: (data.employees.length === 0 && props.loading === false) ? 'block' : 'none',
        zIndex: 10,
        backgroundColor: "#fff"
      }}>
        <p className={classes.loader}> Tidak ada data </p>
      </div>
      <div style={{ display: expired ? 'block' : 'none' }} className={classes.expContainer}>
        <div style={{ marginTop: '30vh' }}>
        <Typography variant="h5" component="h5">
          TokenExpired
        </Typography>
        <Typography variant="body2">
          Silakan minta URL terbaru ke <b>amk@arina.co.id</b>.
        </Typography>
        </div>
      </div>
      <CssBaseline />
      <div id="headerPrint" style={{ display: 'none' }}>
        {Object.keys(selectedUser).length > 0 ? (
          <React.Fragment>
            <Typography variant="body1" noWrap style={{ fontWeight: "600" }}>
              {selectedUser.name}
            </Typography>
            <Typography
              variant="body2"
              component="p"
            >
              {selectedUser.second_city ? `${selectedUser.second_city} - ` : ''}{`${selectedUser.area}`}
            </Typography>
            <Typography
              variant="body2"
              component="p"
            >
              {selectedUser.posisi}
            </Typography>
          </React.Fragment>
        ) : (
          <></>
        )}
      </div>
      <AppBar position="fixed" id="headerNoPrint" className={classes.appBar} style={{ backgroundColor: 'white'}}>
        <Toolbar>
          <Grid container style={{color:'black'}}>
            <Grid item xs={4} style={{ textAlign: 'left' }}>
              <Typography variant="h5" noWrap style={{ fontWeight: "800" }}>
                {data.principal.name} <Typography variant="caption" noWrap style={{ fontSize: 10 }}>{data.employees.length} Karyawan</Typography>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h6" noWrap>
                {data.weeks.length > 0 ? data.weeks[index['weeks']].label : 'Tanggal...'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <span style={{ float: "right" }}>
                {topFilter(data.weeks, 'weeks')}
              </span>
              <span style={{ float: "right" }}>
                {topFilter(data.kantor, 'kantor')}
              </span>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div id="sidaBarNoPrint" className={classes.leftFilterContainer}>
        <TextField
          style={{ display: 'block' }}
          id="searchEmployee"
          label="Search Karyawan"
          placeholder="Nama Karyawan"
          style={{ paddingLeft: 20, paddingRight: 20 }}
          InputLabelProps={{
            className: classes.textLabel,
          }}
          onChange={e => searchKaryawan(e.target.value)}
          value={searchKeyword}
          margin="normal"
          fullWidth
        />
        <TextField
          style={{ display: 'block' }}
          id="areaEmployee"
          select
          style={{ width: "200px" }}
          label="Filter Area Karyawan"
          value={data.areas[index['area']].label}
          onChange={e => handleClickMenu(e, e.currentTarget.selectedIndex, 'area')}
          SelectProps={{
            native: true,
            MenuProps: {
              className: classes.menu,
            },
          }}
          margin="normal"
        >
          {data.areas.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
      </div>
      <Drawer
        id="drawerNoPrint"
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <Divider />
        <AutoSizer>
          {({ height }) => (
            <List
              className="List"
              height={height}
              width={240}
              itemCount={data.employees.length}
              itemSize={90}
            >
              {Row}
            </List>
          )}
        </AutoSizer>
      </Drawer>
    </div>
  );
}

const mapStateToProps = state => ({
  selectedUserAttendancePermit: state.reducer.selectedUserAttendancePermit,
  loading: state.reducer.loading,
});

const mapDispatchToProps = dispatch => ({
  setSelectedUserAttendancePermit: selectedUserAttendancePermit => {
    dispatch(setSelectedUserAttendancePermit(selectedUserAttendancePermit));
  },
  setLoading: loading => {
    dispatch(setLoading(loading));
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PermanentDrawer)