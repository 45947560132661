import React, { useEffect  } from "react";
import './App.css';

// import { SearchProvider } from "./components/SearchContext";

import Router from "./RouterPage";

console.log("REACT_APP_ENV : " + process.env.REACT_APP_ENV);
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

function App() {
  useEffect(() => {
    document.title = `${process.env.REACT_APP_ENV != 'production' ? process.env.REACT_APP_ENV +' - ' : ''}${document.title}`;
  }, []);

  return (
    <div className="App">
    {/* <SearchProvider> */}
      <Router />
    {/* </SearchProvider> */}
    </div>
  );
}

export default App;
