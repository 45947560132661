import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import {
  CircularProgress
} from '@material-ui/core'
import { connect } from "react-redux";
import { setSelectedUserAttendancePermit, setLoading } from "../redux/action";

const useStyles = makeStyles(theme => ({
  loaderContainer: {
    position: 'fixed',
    top: '65px',
    left: '240px',
    width: 'calc(100vw - 240px)',
    height: 'calc(100vh - 65px)'
  },
  loader: {
    position: 'absolute',
    top: 'calc(50% - 50px)',
    left: 'calc(50% - 50px)'
  }
}));

function Loading(props){
  const classes = useStyles();
  return(
    <React.Fragment>
      {props.loading ? (
        <div className={classes.loaderContainer}>
          <CircularProgress className={classes.loader} size={100} />
        </div>
      ) : (
        null
      )}
    </React.Fragment>
  )
}


const mapStateToProps = state => ({
  selectedUserAttendancePermit: state.reducer.selectedUserAttendancePermit,
  loading: state.reducer.loading,
});

const mapDispatchToProps = dispatch => ({
  setSelectedUserAttendancePermit: selectedUserAttendancePermit => {
    dispatch(setSelectedUserAttendancePermit(selectedUserAttendancePermit));
  },
  setLoading: loading => {
    dispatch(setLoading(loading));
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Loading)