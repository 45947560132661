import { combineReducers } from "redux";
const initialState = {
  selectedUserAttendancePermit: {
    attendances: [],
    permits: [],
    rangeDate: []
  },
  loading: false,
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SELECTED_USER":
      return {
        ...state,
        selectedUserAttendancePermit: action.selectedUserAttendancePermit
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: action.loading
      };
    default:
      return state;
  }
};

export default combineReducers({
  reducer,
});
