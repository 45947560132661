export const setSelectedUserAttendancePermit = selectedUserAttendancePermit => ({
  type: "SET_SELECTED_USER",
  selectedUserAttendancePermit
});

export const setLoading = loading => ({
  type: "SET_LOADING",
  loading
});

