import React, { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import PermanentDrawer from "./components/PermanentDrawer";

const IndexPage = lazy(() => import("./pages/IndexPage"));
const DocumentPage = lazy(() => import("./pages/DocumentPage"));
const DetailDocumentPage = lazy(() => import("./pages/DetailDocumentPage"));

const RouterPage = () => {


  return(
    <div>
      { !window.location.href.includes('document') ?
        (<PermanentDrawer />) :
      null }
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
              <Route
                exact
                path="/"
                render={props => (
                  <IndexPage {...props} />
                )}
              />
              <Route
                exact
                path="/document"
                render={props => (
                  <DocumentPage {...props} />
                )}
              />
              <Route
                exact
                path="/detail-document"
                render={props => (
                  <DetailDocumentPage {...props} />
                )}
              />
          </Switch>
        </Suspense>
      </Router>
    </div>
)};

export default RouterPage;